/* You can add global styles to this file, and also import other style files */
.mdc-dialog__container {
  width: 100%;
}

html {
  font: var(--sz-body-large)!important;
}

body {
  user-select: unset!important;
}
